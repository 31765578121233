import React from "react";
import { useSelector } from "react-redux";
import { Container, Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import { memberQuery } from "../client/queries";
import MetaTags from "react-meta-tags";
import theme, {
  Box,
  Title,
  SubTitle,
  Text,
  Button,
  Space,
} from "../theme/theme";
import { Avatar } from "./avatar";
import BlockContent from "@sanity/block-content-to-react";
import { Block } from "@mui/icons-material";

export const Member = ({
  firstname,
  familyname,
  avatar,
  description,
  buttons,
  projects,
  slug,
  segments,
}) => {
  const client = useSelector((state) => state.data.client);
  const storedprojects = useSelector((state) => state.data.projects);

  const Meta = (
    <MetaTags>
      <title>{"CHAMELEON ESRs / " + firstname}</title>
      <meta
        name="description"
        content={
          "CHAMELEON is a Marie Curie Innovative Training Network for European Joint Doctorates. ESR community platform"
        }
      />
      <meta property="og:title" content={"CHAMELEON ESRs / " + firstname} />
    </MetaTags>
  );

  return (
    <div>
      {Meta}
      <br />
      <br />
      <br />
      <Container style={{ margin: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={3}>
            <Avatar avatar={avatar} />
          </Grid>
          <Grid xs={false} md={1} lg={1}>
            &nbsp;
          </Grid>
          <Grid xs={12} md={8} lg={7}>
            <Title>
              {firstname} {familyname}
            </Title>

            {projects ? (
              <div>
                <br />
                <br />
                <SubTitle>Selected Projects</SubTitle>
                {projects.map((b) => {
                  if (client && storedprojects) {
                    let result = null;

                    storedprojects.forEach((p) => {
                      if (p._id == b._ref) {
                        result = p;
                      }
                    });

                    b = result;

                    if (result) {
                      return (
                        <Button key={b._id} to={"/projects/" + b.slug} external>
                          {" "}
                          {b.title}{" "}
                        </Button>
                      );
                    } else {
                      return null;
                    }
                  } else {
                    return null;
                  }
                })}
                <br />
                <br />
                <SubTitle>Main Research</SubTitle>
              </div>
            ) : null}

            <Text>
              {projects ? null : (
                <div>
                  <br />
                  <br />
                </div>
              )}
              <BlockContent blocks={description} />
            </Text>
            <br />
            <br />
            {buttons.map((b) => (
              <Button key={b.title} to={b.url} external>
                {" "}
                {b.title}{" "}
              </Button>
            ))}
            <br />
            <br />
            <br />
            <br />

            {segments && (
              <div>
                <br />
                <br />
              </div>
            )}

            {segments
              ? segments.map(
                  ({ title, description, buttons }, segmentIndex) => (
                    <div key={title}>
                      <SubTitle>{title}</SubTitle>
                      <br />
                      <BlockContent blocks={description} />
                      <br />
                      <br />
                      {buttons
                        ? buttons.map(
                            (
                              {
                                title: buttonTitle,
                                subtitle,
                                thumbnail,
                                url,
                                extraImages,
                              },
                              buttonIndex
                            ) => {
                              const isFirst = buttonIndex === 0; // Check if it is the first button

                              const containerStyle = {
                                border: "2px solid black",
                                borderTop: isFirst ? "2px solid black" : "none", // Only apply top border for the first button
                                borderBottom: "2px solid black",
                                padding: "10px",
                                display: "flex",
                                justifyContent: "space-between", // This pushes the extra images to the right side
                                alignItems: "center",
                              };

                              const imageContainerStyle = {
                                width: "70px", // Fixed width for the thumbnail
                                height: "70px", // Fixed height for the thumbnail
                                marginRight: "10px", // Adds spacing between the image and the title
                              };

                              const thumbnailStyle = {
                                width: "100%", // Ensures the image fills the container
                                height: "100%", // Ensures the image fills the container
                                objectFit: "cover", // Ensures the image covers the container without distortion
                              };

                              const titleStyle = {
                                flex: 1, // Ensures the title takes up the remaining space
                                paddingLeft: "10px", // Adds spacing between the image and title
                              };

                              const extraImagesStyle = {
                                display: "flex",
                                flexDirection: "row", // Stacks extra images horizontally
                                justifyContent: "center", // Centers the extra images horizontally
                                alignItems: "center", // Aligns the extra images in the center
                              };

                              const extraImageStyle = {
                                width: "70px", // Increase the width of each extra image
                                height: "70px", // Increase the height of each extra image
                                marginLeft: "10px", // Adds space between each extra image
                                objectFit: "cover", // Ensures each extra image covers the container
                              };

                              return (
                                <a
                                  href={url}
                                  key={buttonTitle}
                                  target="_blank"
                                  style={{ width: "100%" }}
                                >
                                  <div style={containerStyle}>
                                    <div style={imageContainerStyle}>
                                      <img
                                        src={thumbnail}
                                        alt="thumbnail"
                                        style={thumbnailStyle}
                                      />
                                    </div>
                                    <div style={titleStyle}>
                                      {buttonTitle}
                                      {subtitle && (
                                        <i>
                                          <br />
                                          {subtitle}
                                        </i>
                                      )}
                                    </div>
                                    {extraImages && extraImages.length > 0 && (
                                      <div style={extraImagesStyle}>
                                        {extraImages.map((extraUrl, index) => (
                                          <img
                                            key={extraUrl}
                                            src={extraUrl}
                                            alt={`extra ${index}`}
                                            style={extraImageStyle}
                                          />
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                </a>
                              );
                            }
                          )
                        : null}
                      <br />
                      <br />
                    </div>
                  )
                )
              : null}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

// export const Avatar = ({avatar, title, mobile, url})=>{
//     if (mobile){
//         return <span>
//                 <Link to={url}>
//                 <img src={avatar} style={{width: "70px", borderRadius: "50%", transform: "translate(0, 25px)"}}/>
//                 <Text>
//                 <i style={{margin: "20px"}}>
//                     {title}
//                 </i>
//             </Text>
//             <br/>
//             <br/>
//             </Link>
//         </span>
//     }

//     return  <Link to={url}>
//         <img src={avatar} style={{width: "100%", borderRadius: "50%"}}/>
//         <div style={{textAlign: "center", marginTop:"10px"}}>
//             <Text>
//                 <i>
//                     {title}
//                 </i>
//             </Text>
//         </div>
//     </Link>

// }
