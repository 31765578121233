import { Container, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Button, ResponsiveImage, Title, Text } from "../theme/theme";
import MetaTags from "react-meta-tags";
import { useParams } from "react-router-dom";
import Redshift from "../components/redshift";
import Education from "../components/education";
import Clouds from "../components/clouds";
import Lightning from "../components/lightning";
import { AvatarList } from "../components/avatar";
import Spinner from "../components/spinner";
import { Member } from "../components/member";

// TODO: implement

const Members = (props) => {
  const [member, setMember] = useState(null);
  const [project, setProject] = useState(null);
  const params = useParams();
  const members = useSelector((state) => state.data.members);

  useEffect(() => {
    if (params) {
      if (!member && params.name && members) {
        members.forEach((m) => {
          if (m.firstname.toLowerCase() == params.name) {
            setMember(m);
            console.log(m);
          }
        });
      }
      if (!project && params.project) {
        setProject(params.project);
      }
    }
  });

  if (!members) {
    return <Spinner />;
  } else if (project == "redshift" && member && member.firstname == "Oriel") {
    return <Redshift />;
  } else if (project == "education" && member && member.firstname == "Oriel") {
    return <Education />;
  } else if (project == "clouds" && member && member.firstname == "Oriel") {
    return <Clouds />;
  } else if (project == "lightning" && member && member.firstname == "Oriel") {
    return <Lightning />;
  } else if (member) {
    console.log(member);
    return (
      <Member
        firstname={member.firstname}
        familyname={member.familyname}
        avatar={member.avatar}
        buttons={member.buttons}
        description={member.description}
        projects={member.projects}
        slug={member.slug}
        segments={member.segments}
      />
    );
  } else {
    return (
      <>
        <MetaTags>
          <title>{"CHAMELEON ESRs / Members"}</title>
          <meta
            name="description"
            content={
              "CHAMELEON is a Marie Curie Innovative Training Network for European Joint Doctorates. ESR community platform"
            }
          />
          <meta property="og:title" content={"CHAMELEON ESRs / Home"} />
        </MetaTags>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={false}>
              <Box>
                <Grid item md={0}>
                  <Title>Members</Title>
                  <Text>Under construction</Text>
                  <AvatarList
                    data={members.map(({ firstname, familyname, avatar }) => ({
                      title: `${firstname} ${familyname}`,
                      avatar,
                      url: `/members/${firstname.toLowerCase()}`,
                    }))}
                  />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
};

export default Members;
