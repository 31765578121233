export const redShiftQuery = `*[_type == "redshift" && _id=="redshift"][0]{
    intro{
        title,
        description,
        "img" : img.asset->url
  },
    segments[]{
      title,
      description,
      resources[]{
          _type,
              _key,
        title,
        "description": lineDescription,
        "file" : file.asset->url,
        url
        
      }
    },
      exit{
            title,
            description,
            "img" : img.asset->url,
            "img2" : img2.asset->url
          },
    footer {
      description,
         partners[]{
         url,
         "img" : img.asset->url,
         _key
       }
    }
  }`;

export const educationQuery = `*[_type == "education" && _id=="education"][0]{
    intro{
        title,
        description,
        "img" : img.asset->url
  },
    segments[]{
      title,
      description,
      resources[]{
          _type,
              _key,
        title,
        "description": lineDescription,
        "file" : file.asset->url,
        url
        
      }
    },
      exit{
            title,
            description,
            "img" : img.asset->url,
            "img2" : img2.asset->url
          },
    footer {
      description,
         partners[]{
         url,
         "img" : img.asset->url,
         _key
       }
    }
  }`;

export const cloudsQuery = `*[_type == "clouds" && _id=="clouds"][0]{
    intro{
        title,
        description,
        "img" : img.asset->url
  },
    segments[]{
      title,
      description,
      resources[]{
          _type,
              _key,
        title,
        "description": lineDescription,
        "file" : file.asset->url,
        url
        
      }
    },
      exit{
            title,
            description,
            "img" : img.asset->url,
            "img2" : img2.asset->url
          },
    footer {
      description,
         partners[]{
         url,
         "img" : img.asset->url,
         _key
       }
    }
  }`;

export const lightningQuery = `*[_type == "lightning" && _id=="lightning"][0]{
    intro{
        title,
        description,
        "img" : img.asset->url
  },
    segments[]{
      title,
      description,
      resources[]{
          _type,
              _key,
        title,
        "description": lineDescription,
        "file" : file.asset->url,
        url
        
      }
    },
      exit{
            title,
            description,
            "img" : img.asset->url,
            "img2" : img2.asset->url
          },
    footer {
      description,
         partners[]{
         url,
         "img" : img.asset->url,
         _key
       }
    }
  }`;

export const memberQuery = `*[_type == "member"]{
    firstname, 
    familyname,
    projects[]{_key, _ref},
    description,
    "avatar" : avatar.asset->url,
    buttons,
     segments[]{
    title,
    description,
    buttons[]{
      title,
      subtitle,
      "url": url,
      "thumbnail": thumbnail.asset->url,
      "extraImages": extraImages[].asset->url
    }
  }
  }`;

export const homeQuery = `
  *[_type == "settings"][0]{
      title,
      subtitle,
      intro
    }
    `;

export const SchoolQuery = `
*[_type == "siteSettings"][0]{
    title,
    intro1,
    intro2,
    footer,
    logos[]{
      _key,
      "img":img.asset->url
    },
    projects[]->{
        _id,
      authors[]{
        _key,
        title,
        "avatar": avatar.asset->url
      },
      "background":background.asset->url,
      color,
      feedback,
      "heading":heading.asset->url,
      "logo":logo.asset->url,
      segments[]{
        _key,
        title,
        description,
        supportingGraphics[]{
          _key,
          description,
          title,
          "img":img.asset->url
        }
      },
      title,
      longtitle,
      tagline
    }
  }
`;

export const ProjectQuery = `
*[_type == "project"]{
  _id,
  title,
  "slug" : slug.current,
  description,
  tagline,
  contributors[]{title, "avatar": avatar.asset->url},
  graphics[]{"img": img.asset->url},
  "heading" : heading.asset->url,
}
  
`;
